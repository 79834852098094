
<template>
  <div>
    <CRow>
      <CCol sm>
        <CCard>
          <CCardBody>
            <div class="d-flex justify-content-between mb-3 align-items-center" >
              <p class="titre mb-0">{{ $t("configuation") }}</p>
              <button @click="deleteScreen" class="btn btn-sm btn-danger d-flex align-items-center" style="gap:3px;">
                <span class="material-symbols-outlined" style="font-size: 16px;"> delete </span>
                <span>{{ $t("delete_screen") }}</span>
              </button>
            </div>
            <form @submit.prevent="saveScreen" id="formscreen">
              <label>{{ this.$t("screen_name") }}</label>
              <!--span><a href="#" data-tooltip="I’m the tooltip text." class="tooltiplink">i</a></span-->

              <div class="btn btn-primary tooltipN">
                i
                <div class="top">
                  <div v-html="$t('sous_titre_nom')"></div>
                  <i></i>
                </div>
              </div>
              <CInput
                :placeholder="this.$t('screen_name_placeholder')"
                v-model="screen.name"
                :invalid-feedback="this.$t('name_required')"
                :is-valid="checkName"
              ></CInput>

              <div class="form-group">
                <label for="categories">{{this.$t('category')}}</label>
                <div>
                  <div class="category">
                    <select id="categories" name="category" class="form-control" v-model="screen.category_id">
                      <option value=""></option>
                      <option v-for="c in categories" :key="c.id" v-bind:value="c.id">{{ c.name }}</option>
                    </select>
                    <button class="btn btn-light" @click="showNewC" type="button">
                      <i class="small material-icons">add_circle</i> {{this.$t('new_cat')}}
                    </button>
                  </div>
                  <div v-show="showTextCat" class="newC">
                    <input
                      :placeholder="this.$t('name_cat')"
                      v-model="newCat"
                      class="form-control"
                    />
                    <button class="btn btn-primary" type="button" @click="addCat">{{this.$t('add')}}</button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="timer_screen">{{ $t("timer") }}</label>
                <div class="btn btn-primary tooltipN">
                  i
                  <div class="top">
                    <div v-html="$t('sous_titre_minute')"></div>
                    <i></i>
                  </div>
                </div>
                <input
                  v-model="screen.timer"
                  id="timer_screen"
                  name="timer"
                  type="number"
                  step="0.5"
                  min="0.5"
                  class="form-control"
                />
              </div>
              <div v-if="abo_user.type != 'free'">
                <label>{{$t('bandeau')}}</label>
                <div class="btn btn-primary tooltipN">
                  i
                  <div class="top">
                    <div v-html="$t('sous_titre_bandeau')"></div>
                    <i></i>
                  </div>
                </div>
                <div id="block_bandeau">
                  <div id="block_textarea">
                    <CTextarea
                      :placeholder="$t('bandeau_placeholder')"
                      v-model="screen.bandeau"
                    ></CTextarea>
                  </div>
                  <div id="bock_size_font">
                    <div>
                      {{ $t("taille_du_texte") }} :
                      <select
                        name="bandeau_size"
                        class="form-control"
                        v-model="screen.bandeau_size"
                        style="margin-bottom: 6px"
                      >
                        <option value="small">{{ $t("small_font") }}</option>
                        <option value="medium">{{ $t("medium_font") }}</option>
                        <option value="big">{{ $t("big_font") }}</option>
                      </select>
                    </div>
                    <div>
                      {{ $t("police_texte") }} :
                      <select
                        name="bandeau_font"
                        class="form-control"
                        v-model="screen.bandeau_font"
                      >
                        <option value="font1">Poppins</option>
                        <option value="font2">Roboto Condensed</option>
                        <option value="font3">Libre Baskerville</option>
                      </select>
                    </div>
                    <div>
                      {{ $t("speed") }} :
                      <select
                        name="bandeau_speed"
                        class="form-control"
                        v-model="screen.bandeau_speed"
                      >
                        <option value="slow">{{ $t("slow") }}</option>
                        <option value="normal">{{ $t("normal") }}</option>
                        <option value="fast">{{ $t("fast") }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!--div role="group" class="form-group" data-children-count="1">
                  <div class="d-flex justify-content-between">
                    <label for="uid-mqjopxcj4ef" class="">En diffusion</label>
                    <CSwitch
                      variant="3d"
                      color="success"
                      :checked.sync="screen.online"
                    />
                  </div>
                </div-->
              <div class="d-flex justify-content-between">
                <div class="text-danger">{{ errors.message }}</div>
                <div v-if="!loading" class="text-right">
                  <button class="btn btn-primary" type="submit">
                    {{ $t("save") }}
                  </button>
                </div>
                <div v-else class="text-right">
                  <button class="btn btn-primary" type="submit" disabled>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ $t("saving") }}
                  </button>
                </div>
              </div>
              <!--CAlert :show.sync="successSaved" color="success" fade>{{
                $t("save_success")
              }}</CAlert-->
            </form>
            
            <!-- -->
            <div v-if="screen.medias" class="column_medias">
              <GalerieScreen
                v-bind:medias="screen.medias"
                :screen_id="screen.id"
                :key="reloadGalerieScreen"
                @updatePrev="upPrev"
              ></GalerieScreen>
              <div class="text-right" style="margin-top: 30px">
                <button class="btn btn-primary" @click="popupGalerie = true">
                  {{ $t("add_media") }}
                </button>
              </div>
            </div>

            <CRow v-if="popupGalerie">
              <!--Galerie
                @closeGalerie="closePopupGalerie"
                :screen_id="screen.id"
              ></Galerie-->
               <GaleriePopup
                @closeGalerie="closePopupGalerie"
                :screen_id="screen.id"
              ></GaleriePopup>
            </CRow>

            <!-- -->
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm>
        <CCard>
          <CCardBody>
            <p class="titre">{{ $t("previsualisation") }}</p>
            <div class="containerFrame">
              <iframe class="responsive-iframe" :src="urlDiffIframe"></iframe>
            </div>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardBody>
            <p class="titre">{{ $t("diff") }}</p>
            <div class="form-group">
              <label for="">{{ $t("url_diffusion") }}</label>

              <div class="btn btn-primary tooltipN">
                i
                <div class="top">
                  <div v-html="$t('sous_titre_url_diff')"></div>
                  <i></i>
                </div>
              </div>
              <div class="d-flex">
                <input
                  v-model="urlDiff"
                  disabled
                  class="form-control urlDiff"
                />
                <button class="btn btn-light" @click="copyURL">
                  <i class="small material-icons">content_copy</i>
                </button>
              </div>
              <!--div class="calert_div">
                <CAlert :show.sync="urlCopied" color="success" fade>{{
                  $t("copied")
                }}</CAlert>
              </div-->
            </div>
            <!--
            <div v-if="abo_user.type != 'free'">
              <p @click="showAdOption = !showAdOption" class="optLink">
                <span v-show="!showAdOption">{{
                  $t("afficher_opt_avance")
                }}</span>
                <span v-show="showAdOption">{{ $t("cacher_opt_avance") }}</span>
              </p>
              <div class="form-group blockOptAdvanced" v-show="showAdOption">
                <label for="">{{ $t("global_video") }}</label>
                <div class="btn btn-primary tooltipN">
                  i
                  <div class="top">
                    <div v-html="$t('sous_titre_link_video')"></div>
                    <i></i>
                  </div>
                </div>
                <span
                  v-if="
                    screen.fullvideo_link_sh &&
                    screen.fullvideo_status == 'done'
                  "
                >
                  <div class="d-flex">
                    <input
                      v-model="urlVidFull"
                      disabled
                      class="form-control urlVidFull"
                    />
                    <button class="btn btn-light" @click="copyURLvideo">
                      <i class="small material-icons">content_copy</i>
                    </button>
                  </div>
                  <div class="calert_div">
                    <CAlert :show.sync="urlVideoCopied" color="success" fade>{{
                      $t("copied")
                    }}</CAlert>
                  </div>
                </span>
                <span v-else
                  ><p>Statut : {{ $t(screen.fullvideo_status) }}</p></span
                >
                <p
                  v-if="
                    (screen.fullvideo_status == 'done' ||
                      screen.fullvideo_status == 'none') &&
                    showGButton
                  "
                >
                  <button
                    class="btn btn-primary btn_generate_video"
                    @click="generateVideo"
                  >
                    {{ $t("generate_video") }}
                  </button>
                </p>
              </div>
            </div>
          -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import GalerieScreen from "@/components/GalerieScreen.vue";
//import Galerie from "@/components/Galerie.vue";
import GaleriePopup from "@/components/GaleriePopup.vue";

export default {
  name: "Screen",
  components: {
    GalerieScreen,
    GaleriePopup,
  },
  data() {
    return {
      id: this.$route.params.id,
      screen: "",
      loading: false,
      errors: { message: "" },
      isActive: true,
      successSaved: 0,
      popupGalerie: false,
      reloadGalerieScreen: 1,
      globalURL: process.env.VUE_APP_MEDIA_URL,
      apiURL: process.env.VUE_APP_API_URL,
      urlCopied: 0,
      urlVideoCopied: 0,
      showGButton: true,
      showAdOption: false,
      abo_user: this.$store.state.user.abos[0],
      randomForRefresh: Math.random(),
      errorsCatch: null,
      categories: null,
      category: null,
      newCat: "",
      showTextCat: false
    };
  },
  computed: {
    urlDiff: function () {
      return this.apiURL + "/rbpi/" + this.screen.id_rasb;
    },
    urlDiffIframe: function () {
      return (
        this.apiURL +
        "/rbpi/" +
        this.screen.id_rasb +
        "?r=" +
        this.randomForRefresh
      );
    },
    urlVidFull: function () {
      /*return (
        this.apiURL +
        "/images/" +
        this.$store.state.user.id +
        "/" +
        this.screen.id_rasb +
        ".mp4"
      );*/
      return this.screen.fullvideo_link_sh;
    },
  },

  async created() {
    let rep = await window.axios.get("/api/screen/" + this.id);
    this.screen = rep.data.screen;

    let repC = await window.axios.get("/api/category/all");
    this.categories = repC.data.categories;

    /*window.axios.get("/api/screen/" + id_screen).then((rep) => {
      self.screen = rep.data.screen;
      console.log('done')
      console.log(self.screen);
      self.loading = false
    })
    .catch(error =>{
      console.log(error)
      self.loading = false
    })
    .finally(()=>{
      self.loading = false
      console.log('finnaly', this.loading)
    })*/
  },
  methods: {
    deleteScreen(){
      this.$confirm({
        message: this.$t('confirmation'),
        button: {
          no: this.$t('non'),
          yes: this.$t('oui'),
        },
        callback: async (confirm) => {
          if (confirm) {
            await window.axios.delete("/api/screen/"+this.screen.id);
            this.$router.push({ path: '/dashboard' })
          }
        },
      });
      
    },
    showNewC(){
      this.showTextCat = true
    },
    async addCat(){
      let rep = await window.axios.post("/api/categories", {name: this.newCat});
      this.categories.push(rep.data.category)
      this.newCat = ""
      this.showTextCat = false
      this.screen.category_id = rep.data.category.id

      this.$notify({
        group: "foo",
        title: "BizScreen",
        text: 'ajouté avec succés',
        type: "success",
      });
    },
    async copyURL() {
      const myText = this.urlDiff;
      await navigator.clipboard.writeText(myText);
      //this.urlCopied = 1;

      this.$notify({
        group: "foo",
        title: "BizScreen",
        text: this.$t("copied"),
        type: "success",
      });
    },
    async copyURLvideo() {
      const myText = this.urlVidFull;
      await navigator.clipboard.writeText(myText);
      //this.urlVideoCopied = 1;
      this.$notify({
        group: "foo",
        title: "BizScreen",
        text: this.$t("copied"),
        type: "success",
      });
    },
    async closePopupGalerie() {
      this.popupGalerie = false;
      //this.$forceUpdate()
      let rep = await window.axios.get("/api/screen/" + this.id);
      this.screen = rep.data.screen;
      this.reloadGalerieScreen++;
      this.upPrev();
    },
    async test(){
      try {
        await window.axios.delete("/api/screen/5/category");
        
      } catch (err) {
        this.errors = err.response.data;
      }
    },

    async saveScreen() {
      this.loading = true;
      try {
        let rep = await window.axios.put("/api/screen/" + this.id, this.screen);
        this.screen = { ...this.screen, ...rep.data.screen };
        this.successSaved = 1;
        this.errors.message = "";
        this.$notify({
          group: "foo",
          title: "BizScreen",
          text: this.$t("save_success"),
          type: "success",
        });
      } catch (err) {
        this.errors = err.response.data;
      } finally {
        this.loading = false;
        this.upPrev();
      }
    },
    upPrev() {
      this.randomForRefresh = Math.random();
    },
    checkName() {
      return this.screen.name == "" ? false : true;
    },
    async generateVideo() {
      this.showGButton = false;
      this.screen.fullvideo_status = "ongoing";

      try {
        await window.axios.post(
          "/api/screen/" + this.id + "/generatefullvideo"
        );

        this.idInterval = setInterval(async () => {
          try {
            const rep = await window.axios.get(
              "/api/screen/" + this.screen.id + "/getstatusfullvideo"
            );

            console.log("response interval fetch : ", rep);
            if (rep.data.status == "done") {
              clearInterval(this.idInterval);

              this.screen.fullvideo_link_sh = rep.data.link;
              this.screen.fullvideo_status = "done";
            }
          } catch (err) {
            this.errorsCatch = err;
          }
        }, 3000);
      } catch (err) {
        this.showGButton = true;
        console.log(err.response);
        if (err.response.data.msg == "too_much_use")
          this.screen.fullvideo_status = "error - limitation à 15 minutes";
        else this.screen.fullvideo_status = "error - please try again later";
      }

      /*try {
        await window.axios.post(
          "/api/screen/" + this.id + "/generatefullvideo"
        );

        

      } catch (err) {
        this.showGButton = true;
        console.log(err.response);
        if (err.response.data.msg == "too_much_use")
          this.screen.fullvideo_status = "error - limitation à 15 minutes";
        else this.screen.fullvideo_status = "error - please try again later";
      }*/
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 2rem;
}
.form-control.is-valid {
  border-color: #d8dbe0 !important;
  background-image: none !important;
}
.titre {
  font-size: 20px;
}

.sousTitre {
  font-size: 12px;
  font-style: italic;
  margin-top: -10px;
  margin-bottom: 8px;
}

.urlDiff,
.urlVidFull {
  max-width: 400px;
  margin-right: 10px;
}
.calert_div {
  margin-top: 8px;
  max-width: 400px;
}

.btn_generate_video {
  margin-top: 15px;
}

.column_medias {
  flex-direction: column;
  margin-top: 60px;
}

label {
  margin-right: 6px;
}

.optLink {
  text-decoration: underline;
}

.optLink:hover {
  cursor: pointer;
}

.blockOptAdvanced {
  transition: all 0.5s;
}

#block_bandeau {
  
  margin-bottom: 30px;
}

#block_textarea {
  width: 100%;
}

#block_textarea > div{
  margin-bottom: 12px;
}

#block_textarea div textarea,
#block_textarea div,
textarea,
textarea.form-control {
  height: 100% !important;
}

#bock_size_font{
  display: flex;
  gap: 10px;

}
#bock_size_font > * { flex: 1; }

.category{
  display: flex;
  gap: 8px;
}
.category button{
  min-width: 200px;
}

.newC{
  gap: 8px;
  margin-top: 5px;
  display: flex;
}

</style>
