<template>
  <!--div v-for="m in medias" :key="m.id">{{ m.file_name }} n</div-->
  <div>
  <div>
    <div class="block_media_title">
      <p class="titreMediaEcran">{{ $t("media_on_screen") }}</p>
      <div v-if="abo_user.type != 'free'">
        <p @click="showAdOption = !showAdOption" class="optLink">
          
          <span v-show="!showAdOption">{{ $t("afficher_opt_avance") }}</span>
          <span v-show="showAdOption">{{ $t("cacher_opt_avance") }}</span>
        </p>
      </div>
    </div>
            <p class="sousTitreMediaEcran">
              {{ $t("sous_titre_media_on_screen") }}
            </p>
  </div>
  <div>
    <draggable v-model="allMedias" @end="onEnd" id="containerMedias">
      <transition-group type="transition">
        <div
          v-for="(element, index) in allMedias"
          :key="index"
          class="cursorMove blockImg col-lg-4"
        >
          <span v-if="element.type == 'picture'">
            <img :src="element.url_thumb" class="img-fluid" :id="element.id" />
          </span>
          <span v-else>
            <!--video controls="controls" preload="metadata">
              <source :src="element.url + '#t=0.1'" type="video/mp4" />
            </video-->
            <video
                          width="100%"
                          controls="controls"
                          preload="none" 
                          :poster="element.url_thumb"
                        >
                          <source
                            :src="element.url"
                            type="video/mp4"
                          />
                        </video>
          </span>
          <!--span class="removeMedia" @click="removeMedia(element.id)">
            <CIcon name="cil-x-circle" />
          </span-->
          <button
            type="button"
            class="btn btn-danger supBtn"
            @click="removeMedia(element.id)"
          >
            {{ $t("delete_from_screen") }}
          </button>
          <div v-if="abo_user.type != 'free'">
            
            <div class=" blockOptAdvanced" v-show="showAdOption">
              <div v-if="element.type == 'picture'">
                <button
                  type="button"
                  class="btn btn-light supBtn rotBtn"
                  @click="rotate(element.id, -90)"
                >
                  <!--i class="material-icons">rotate_left</i-->
                  <span class="material-symbols-outlined">rotate_left</span>
                </button>
                <button
                  type="button"
                  class="btn btn-light supBtn  rotBtn"
                  @click="rotate(element.id, 90)"
                >
                  <!--i class="material-icons">rotate_right</i-->
                  <span class="material-symbols-outlined">rotate_right</span>
                </button>
              </div>
              <!--div else style="height:55px"></div-->
              <p style="margin-top:12px;margin-bottom:5px;">{{ $t("diff_prog") }}</p>
              <div class="blockDate">
                <p>{{ $t("start_diffusion") }}</p>
                <datepicker
                  :bootstrap-styling="true"
                  v-model="element.start_diff"
                  :clear-button="true"
                  @input="setStartDiff(element.id)"
                >
                </datepicker>
              </div>

              <div class="blockDate">
                <p>{{ $t("end_diffusion") }}</p>
                <datepicker
                  :bootstrap-styling="true"
                  v-model="element.end_diff"
                  :clear-button="true"
                  @input="setEndDiff(element.id)"
                ></datepicker>
              </div>
              
              <p style="margin-top:35px;margin-bottom:5px;">{{ $t("heure_diffusion") }}</p>
              <div class="blockDate">
                <p>{{ $t("start_diffusion") }}</p>
                <div class="time_block">
                  <input class="form-control" type="time" v-model="element.time_diff.time_start_diff" @input="updateTime(index)">
                </div>
              </div>
              <div class="blockDate">
                <p>{{ $t("end_diffusion") }}</p>
                <div class="time_block">
                  <input class="form-control"  type="time" v-model="element.time_diff.time_end_diff" @input="updateTime(index)">
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </transition-group>
    </draggable>

    

  </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Datepicker from "vuejs-datepicker";
export default {
  name: "GalerieScreen",
  components: {
    draggable,
    Datepicker,
  },
  props: {
    medias: Array,
    screen_id: Number,
  },
  data: function () {
    return {
      // allMedias: [...this.medias]
      allMedias: [],
      abo_user: this.$store.state.user.abos[0],
      masks: {
        input: "YYYY-MM-DD h:mm A",
      },
      showAdOption: false,
      test: 3,

      /*
      time_diff: {
        h_start: "",
        m_start: "",
        h_end: "",
        m_end: "",
      }*/
    };
  },
  computed: {
    background(m) {
      return process.env.VUE_APP_MEDIA_URL + m;
    },
  },
  created() {
    //created clean data
    /*console.log(this.medias, "here vv", this.abo_user);
    console.log(process.env.VUE_APP_MEDIA_URL);*/

    for (var i = 0; i < this.medias.length; i++) {
     /* const timeStart = this.medias[i].pivot.time_start_diff.split(':')
      const hStart = timeStart[0]
      const mStart = timeStart[1]*/
      this.allMedias[i] = {
        id: this.medias[i].id,
        file_name: this.medias[i].file_name,
        ordre: this.medias[i].pivot.ordre,
        url: process.env.VUE_APP_MEDIA_URL + this.medias[i].file_name,
        type: this.medias[i].type,
        start_diff: this.medias[i].pivot.start_diff,
        end_diff: this.medias[i].pivot.end_diff,
        url_thumb: process.env.VUE_APP_MEDIA_URL + this.medias[i].thumb,
        time_diff: {
          time_start_diff: this.medias[i].pivot.time_start_diff,
          time_end_diff: this.medias[i].pivot.time_end_diff,
        }
      };
    }
  },
  methods: {
    updateTime(i){
      //console.log('heree', this.allMedias[i].time_diff.time_start_diff)
      const self = this
      window.axios
        .put("/api/updatetime/" + self.screen_id + "/" + self.allMedias[i].id, {
          time_start_diff: self.allMedias[i].time_diff.time_start_diff,
          time_end_diff: self.allMedias[i].time_diff.time_end_diff,
        })
        .then(() => {
          console.log("done");
        });
    },
    saveTime(i){
      const self = this
      window.axios
        .put("/api/updatetime/" + self.screen_id + "/" + self.allMedias[i].id, {
          time_start_diff: self.allMedias[i].time_diff.time_start_diff,
          time_end_diff: self.allMedias[i].time_diff.time_end_diff,
        })
        .then((rep) => {
          console.log("done", rep);
          this.$notify({
            group: "foo",
            title: "BizScreen",
            text: 'enregistré avec succés',
            type: "success",
          });
        });
    },
    onEnd() {
      console.log(this.allMedias);
      //screen/{screen_id}/ordermedia
      const self = this;
      window.axios
        .put("/api/screen/" + self.screen_id + "/ordermedia", {
          medias: self.allMedias,
        })
        .then((rep) => {
          console.log("done", rep);
          this.$emit("updatePrev");
        });
    },
    removeMedia(id) {
      const self = this;
      window.axios
        .delete("/api/screen/" + self.screen_id + "/" + id)
        .then(() => {
          self.allMedias = self.allMedias.filter(function (value) {
            return value.id != id;
          });
        });
    },
    setStartDiff(media) {
      const found = this.allMedias.find((element) => element.id == media);

      var date_elem = "";
      if (found.start_diff && found.start_diff !== "")
        date_elem = found.start_diff.toISOString().slice(0, 10) + " 00:00:01";

      const self = this;
      window.axios
        .put("/api/updatestartdiff/" + self.screen_id + "/" + found.id, {
          start_diff: date_elem,
        })
        .then((rep) => {
          console.log("done", rep);
        });
    },
    setEndDiff(media) {
      const found = this.allMedias.find((element) => element.id == media);

      var date_elem = "";
      if (found.end_diff && found.end_diff !== "")
        date_elem = found.end_diff.toISOString().slice(0, 10) + " 00:00:01";

      const self = this;
      window.axios
        .put("/api/updateenddiff/" + self.screen_id + "/" + found.id, {
          end_diff: date_elem,
        })
        .then((rep) => {
          console.log("done", rep);
        });
    },
    rotate(media, deg) {
      const found = this.allMedias.find((element) => element.id == media);

      const url = found.url_thumb;
  console.log('hereeee XXX : '+url)
      const self = this;
      window.axios
        .put("/api/rotate/" + self.screen_id + "/" + found.id + "/" + deg)
        .then(() => {
          //update view
          document.getElementById(media).src = url + "?r=" + Math.random();
        });
    },
  },
};
</script>

<style scoped>
#containerMedias span {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  
}

.cursorMove:hover {
  cursor: move;
}

#containerMedias img {
  padding: 10px;
}

.blockImg {
  /*max-width: 350px;*/
  text-align: center;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

video {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
 /* max-width: 145px ;*/
  width: 100%;
}

.blockDate {
  margin-top: 6px;
}
.blockDate p {
  margin-bottom: 2px;
}

.supBtn {
  margin-bottom: 15px;
}

.btn-light{
  margin-left: 5px;
  margin-right: 5px;
  padding-right: 20px;
}

.blockImg{
  border: 2px solid #80808047;
    border-radius: 5px;
    
    padding-bottom: 8px;
    padding-top: 8px;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.rotBtn{
  padding: 8px;
}

.rotBtn span{
  font-size: 22px;
  font-weight: bold;
}

.optLink{
  text-decoration: underline;
}

.optLink:hover{
  cursor: pointer;
}

.titreMediaEcran {
 
  
  font-size: 20px;
}

.sousTitreMediaEcran {
  margin-top: -15px;
 
  font-size: 12px;
  font-style: italic;
}

.block_media_title{
  display: flex;
    justify-content: space-between;
    align-items: center;
}

.time_block{
  display: flex;
  gap: 5px;
  align-items: center;
}

@media (min-width: 992px)
{
  #containerMedias .col-lg-4 {
      flex: 0 0 31%;
      max-width: 31%;
  }
}
</style>
