<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="header-container">
            <div class="header-container__title">
              <span class="material-symbols-outlined"> photo_library </span>
              Gallerie
            </div>
            <div class="header-container__close">
              <span @click="close" class="close_icon material-symbols-outlined">
                close
              </span>
            </div>
          </div>
          <div class="inner-container">
            <Galerie :screen_id="screen_id"></Galerie>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
  
  <script>
import Galerie from "@/components/Galerie.vue";

export default {
  name: "GaleriePopup",
  components: {
    Galerie,
  },
  props: {
    screen_id: Number,
  },
  data: function () {
    return {};
  },
  methods: {
    close() {
      this.$emit("closeGalerie", false);
    },
  },
};
</script>
  
  <style scoped>
.header-container {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background-color: #ebedef;
  border-bottom: 1px solid #cbcbcb;
  justify-content: space-between;
}
.close_icon {
  /*
    font-weight: bold;
    position: sticky;
    top: 3px;
    width: 24px;
    height: 24px;
    left: calc(100%);
    z-index: 999999999;
    margin-right: 3px;*/
}
.close_icon {
  font-weight: 600;
  font-size: 20px;
}
.close_icon:hover {
  cursor: pointer;
}

.header-container__title {
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 15px;
}

.header-container__title span {
  font-size: 20px;
}

.header-container__close {
  height: 25px;
  width: 25px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.header-container__close:hover {
  background-color: white;
}
</style>
  